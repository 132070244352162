// App.jsx

import React, { useState } from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  Chart,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ArcElement,
  LineElement,
  PointElement,
} from 'chart.js';
import './App.css';

// Register Chart.js components
Chart.register(
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ArcElement,
  LineElement,
  PointElement
);

// Helper function to calculate total interest paid over a specific term
const calculateTotalInterestPaid = (
  principal,
  annualRate,
  termYears,
  mortgageTermYears = 25
) => {
  const monthlyRate = annualRate / 100 / 12;
  const totalPayments = mortgageTermYears * 12;

  // Calculate monthly payment using the amortization formula
  const monthlyPayment =
    (principal * monthlyRate * Math.pow(1 + monthlyRate, totalPayments)) /
    (Math.pow(1 + monthlyRate, totalPayments) - 1);

  // Total payment over the term
  const totalPayment = monthlyPayment * totalPayments;

  // Total interest paid
  const totalInterest = totalPayment - principal;

  return totalInterest;
};

function App() {
  const [step, setStep] = useState(0);
  const [members, setMembers] = useState(null);
  const [conversionRate, setConversionRate] = useState(null);
  const [mortgageAmount, setMortgageAmount] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [unlockedMembersWithKarla, setUnlockedMembersWithKarla] = useState([]);
  const [netRevenueWithKarla, setNetRevenueWithKarla] = useState([]);
  const [ascendCost, setAscendCost] = useState(null);
  const [finalRoi, setFinalRoi] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // State variables for cost breakdown
  const [totalActiveMembersCost, setTotalActiveMembersCost] = useState(0);
  const [licensingFee, setLicensingFee] = useState(0);
  const [totalRewardsCost, setTotalRewardsCost] = useState(0);
  const [rewardsCostByYear, setRewardsCostByYear] = useState([]);
  const [activeMembersCostByYear, setActiveMembersCostByYear] = useState([]);

  // State variable for totalInterestPaid
  const [totalInterestPaid, setTotalInterestPaid] = useState(0);

  // Step Handlers
  const handleMembersClick = (num) => {
    setMembers(num);
    setStep(step + 1);
  };

  const handleConversionClick = (rate) => {
    setConversionRate(rate);
    setStep(step + 1);
  };

  const handleMortgageClick = (amount) => {
    setMortgageAmount(amount);
    setStep(step + 1);
  };

  const handleInterestClick = (rate) => {
    setInterestRate(rate);
    setStep(step + 1);
  };

  const handleCalculateClick = () => {
    setStep(step + 1);
    setIsCalculating(true);

    setTimeout(() => {
      const improvedConversionRate = conversionRate * 10; // 10x improvement

      // Calculate total interest paid over 25 years based on interest rate
      const calculatedTotalInterestPaid = calculateTotalInterestPaid(
        mortgageAmount,
        interestRate,
        25, // termYears (full mortgage term)
        25 // mortgageTermYears
      );
      setTotalInterestPaid(calculatedTotalInterestPaid);

      // With Karla Calculations
      let remainingActiveMembers = members;
      const unlockedMembersWithKarlaList = [];
      const netRevenueWithKarlaList = [];

      // Initialize local variables for accumulation
      let totalRewardsCostCalc = 0;
      let totalActiveMembersCostCalc = 0;
      const rewardsMultipliers = [1200, 2400, 3600, 4800, 5000]; // Year 1 to 5
      const rewardsCostYearly = [];
      const activeMembersCostYearly = [];

      for (let year = 1; year <= 5; year++) {
        const unlockedThisYear = Math.round(
          remainingActiveMembers * improvedConversionRate
        );
        unlockedMembersWithKarlaList.push(unlockedThisYear);

        const netRevenueThisYear =
          unlockedThisYear * calculatedTotalInterestPaid;
        netRevenueWithKarlaList.push(netRevenueThisYear.toFixed(2));

        // Rewards Calculation
        const rewardsCostYear =
          unlockedThisYear * rewardsMultipliers[year - 1];
        totalRewardsCostCalc += rewardsCostYear;
        rewardsCostYearly.push(rewardsCostYear);

        // Active Members Cost Calculation
        const activeMembersCostYear = remainingActiveMembers * 2 * 12; // $2/member/month
        totalActiveMembersCostCalc += activeMembersCostYear;
        activeMembersCostYearly.push(activeMembersCostYear);

        // Update remaining active members
        remainingActiveMembers -= unlockedThisYear;
        remainingActiveMembers = Math.max(0, remainingActiveMembers); // Prevent negative
      }

      setUnlockedMembersWithKarla(unlockedMembersWithKarlaList);
      setNetRevenueWithKarla(netRevenueWithKarlaList);

      // Licensing Fee Calculation
      const licensingFeeCalc = 42000 * 5; // $42,000 per year for 5 years
      setLicensingFee(licensingFeeCalc);

      // Total Ascend Cost Calculation
      const ascendCostCalc =
        totalActiveMembersCostCalc + licensingFeeCalc + totalRewardsCostCalc;
      setAscendCost(ascendCostCalc.toLocaleString());

      // ROI Calculation for 'With Karla'
      const totalNetRevenueWithKarla = unlockedMembersWithKarlaList.reduce(
        (acc, value) => acc + value * calculatedTotalInterestPaid,
        0
      );
      const roiCalc = (
        ((totalNetRevenueWithKarla - ascendCostCalc) / ascendCostCalc) *
        100
      ).toFixed(2);
      setFinalRoi(roiCalc);

      // Set Cost Breakdown States
      setTotalActiveMembersCost(totalActiveMembersCostCalc);
      setTotalRewardsCost(totalRewardsCostCalc);
      setRewardsCostByYear(rewardsCostYearly);
      setActiveMembersCostByYear(activeMembersCostYearly);

      setIsCalculating(false);
      setStep(9);
    }, 3000);
  };

  // Bar Chart Data for Revenue Impact
  const revenueData = {
    labels: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
    datasets: [
      {
        label: 'Without Karla',
        data: unlockedMembersWithKarla.map((withKarlaRevenue) => {
          const withoutKarlaRevenue =
            (withKarlaRevenue * totalInterestPaid) / 10;
          return withoutKarlaRevenue.toFixed(2);
        }),
        backgroundColor: '#4e79a7',
        borderWidth: 2,
      },
      {
        label: 'With Karla',
        data: netRevenueWithKarla,
        backgroundColor: '#f28e2b',
        borderWidth: 2,
      },
    ],
  };

  // Bar Chart Options with Custom Tooltip
  const revenueOptions = {
    plugins: {
      tooltip: {
        enabled: false,
        external: (context) => {
          // Custom Tooltip Code
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            setTooltipContent(null);
            return;
          }
          const index = tooltipModel.dataPoints[0].dataIndex;
          const datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
          const canvasPosition = context.chart.canvas.getBoundingClientRect();

          const position = {
            x: canvasPosition.left + tooltipModel.caretX,
            y: canvasPosition.top + tooltipModel.caretY - 60,
          };
          setTooltipPosition(position);

          const isKarlaDataset = datasetIndex === 1;
          const currentConversionRate = isKarlaDataset
            ? (conversionRate * 10 * 100).toFixed(2)
            : (conversionRate * 100).toFixed(2);
          const activeMembersConverted = isKarlaDataset
            ? unlockedMembersWithKarla[index]
            : Math.round(unlockedMembersWithKarla[index] / 10);
          const totalInterestEarned = (
            activeMembersConverted * totalInterestPaid
          ).toLocaleString();

          setTooltipContent({
            title: isKarlaDataset ? 'With Karla' : 'Without Karla',
            content: `
              Year ${index + 1}:
              Conversion Rate: ${currentConversionRate}%
              Members Converted: ${activeMembersConverted}
              Interest from Mortgages: $${totalInterestEarned}
            `,
          });
        },
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return '$' + value.toLocaleString();
          },
        },
      },
    },
  };

  // ESG Line Chart Data for Impact
  const esgData = {
    labels: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
    datasets: [
      {
        label: 'Credit Score Growth',
        data: [500, 620, 680, 730, 765], // Sample data for credit score growth
        borderColor: 'blue',
        backgroundColor: 'transparent',
        pointBackgroundColor: 'blue',
        fill: false,
        tension: 0.3,
      },
      {
        label: 'Converted Minorities into Mortgages (Sample)',
        data: [300, 450, 700, 800, 1000], // Sample data for mortgage conversion of minorities
        borderColor: 'orange',
        backgroundColor: 'transparent',
        pointBackgroundColor: 'orange',
        fill: false,
        tension: 0.3,
      },
    ],
  };

  // Pie Chart Data for Cost Breakdown
  const costData = {
    labels: ['Active Members Cost', 'Licensing Fee', 'Rewards'],
    datasets: [
      {
        data: [totalActiveMembersCost, licensingFee, totalRewardsCost],
        backgroundColor: ['#4e79a7', '#f28e2b', '#76b7b2'],
        borderWidth: 2,
      },
    ],
  };

  // Pie Chart Options with Custom Tooltip
  const costOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            let label = '';
            switch (index) {
              case 0:
                label = [
                  'Active Members Cost:',
                  `Total over 5 Years: $${totalActiveMembersCost.toLocaleString()}`,
                ];
                activeMembersCostByYear.forEach((cost, year) => {
                  label.push(`Year ${year + 1}: $${cost.toLocaleString()}`);
                });
                break;
              case 1:
                label = [
                  'Licensing Fee:',
                  `Total over 5 Years: $${licensingFee.toLocaleString()}`,
                  `Per Year: $${(licensingFee / 5).toLocaleString()}`,
                ];
                break;
              case 2:
                label = [
                  'Rewards Cost:',
                  `Total over 5 Years: $${totalRewardsCost.toLocaleString()}`,
                ];
                rewardsCostByYear.forEach((cost, year) => {
                  label.push(`Year ${year + 1}: $${cost.toLocaleString()}`);
                });
                break;
              default:
                label = '';
                break;
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  // Handler to generate PDF
  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Set default font and colors
    doc.setFont('Helvetica');
    doc.setTextColor(40);

    // Initialize current Y position
    let currentY = 20;

    // Function to add text with spacing
    const addText = (text, options = {}) => {
      const { fontSize = 12, lineHeight = 7, maxWidth = 170, fontStyle = 'normal' } = options;
      doc.setFontSize(fontSize);
      doc.setFont(undefined, fontStyle);
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, 20, currentY);
      currentY += lines.length * lineHeight;
      if (currentY > 280) {
        doc.addPage();
        currentY = 20;
      }
    };

    // Function to add headings
    const addHeading = (text, fontSize = 16) => {
      doc.setFontSize(fontSize);
      doc.setFont(undefined, 'bold');
      doc.text(text, 20, currentY);
      currentY += 10;
      doc.setFont(undefined, 'normal');
    };

    // Title
    doc.setFontSize(22);
    doc.setFont(undefined, 'bold');
    doc.text('Karla Ascend Summary Report', 105, currentY, {
      align: 'center',
    });
    currentY += 15;
    doc.setFont(undefined, 'normal');

    // 1. Introduction
    addHeading('1. Introduction to Karla Ascend');
    addText(
      'Empowering Credit Unions to Accelerate Homeownership',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText(
      'Welcome to Karla Ascend, the cutting-edge platform designed to transform how credit unions engage with their members. Our mission is to bridge the gap between renters and homeowners by providing data-driven insights and financial tools that enhance credit-building and mortgage readiness.'
    );

    // 2. How Karla Ascend Works
    addHeading('2. How Karla Ascend Works');
    addText(
      'Seamless Integration for Enhanced Member Conversion',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText(
      'Karla Ascend leverages advanced AI-powered financial wellness tools to monitor and guide members toward improving their credit scores and saving for down payments. By partnering with marketing agencies, landlords, and tenant insurance providers, we create a robust ecosystem that nurtures leads and converts them into long-term mortgage clients.'
    );
    addText('Key Features:');
    addText('- Credit Monitoring & Reporting: Helps members improve their credit scores through timely rent payments.');
    addText('- Savings Optimization: Identifies personalized savings opportunities for members.');
    addText('- HomeFunds Program: Bridges the gap for down payments, making homeownership attainable.');
    addText('- Partnership Ecosystem: Collaborates with key stakeholders to generate and nurture leads.');

    // 3. Benefits for Credit Unions
    addHeading('3. Benefits for Credit Unions');
    addText(
      'Unlocking New Revenue Streams and Member Loyalty',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText('Implementing Karla Ascend offers numerous benefits:');
    addText('- Increased Conversion Rates: Boost mortgage applications by up to 10x.');
    addText('- Sustainable Revenue Growth: Generate significant interest income over the mortgage term.');
    addText('- Enhanced Member Engagement: Provide valuable financial tools that increase member satisfaction and retention.');
    addText('- Competitive Edge: Modernize your offerings to meet the demands of today’s financially savvy members.');

    // 4. ROI Analysis
    addHeading('4. ROI Analysis');
    addText(
      'Maximizing Your Return on Investment',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText(
      'Based on our refined calculations, Karla Ascend delivers exceptional ROI by converting renters into mortgage-ready clients. Here’s how:'
    );

    // Example Scenario
    addText(
      `Number of Members: ${members}\n` +
        `Current Conversion Rate: ${(conversionRate * 100).toFixed(2)}%\n` +
        `Improved Conversion Rate with Karla: ${(conversionRate * 10 * 100).toFixed(
          2
        )}%\n` +
        `Average Mortgage Amount: $${mortgageAmount.toLocaleString()}\n` +
        `Interest Rate: ${interestRate}%\n` +
        `Mortgage Term: 25 Years`
    );

    // Calculations
    addHeading('Calculations:', 14);
    addText('Total Interest per Mortgage:');
    addText(`$${totalInterestPaid.toLocaleString()}`);
    currentY += 5;

    addText('Yearly Conversions:');
    currentY += 5;
    unlockedMembersWithKarla.forEach((membersConverted, index) => {
      addText(`Year ${index + 1}: ${membersConverted} members converted`);
      addText(`Net Revenue: $${parseFloat(netRevenueWithKarla[index]).toLocaleString()}`);
      currentY += 5;
      if (currentY > 280) {
        doc.addPage();
        currentY = 20;
      }
    });

    addText(`Running Ascend for your ${members} active members will cost $${ascendCost}.`);
    addText(`Ascend's ROI: ${finalRoi}%!`);

    // 5. Cost Breakdown
    addHeading('5. Cost Breakdown');
    addText(
      'Comprehensive Overview of Ascend Costs',
      { fontSize: 14, fontStyle: 'italic' }
    );

    // Cost Table using autoTable
    doc.autoTable({
      head: [['Cost Component', 'Amount ($)']],
      body: [
        ['Active Members Cost', totalActiveMembersCost.toLocaleString()],
        ['Licensing Fee', licensingFee.toLocaleString()],
        ['Rewards Cost', totalRewardsCost.toLocaleString()],
        ['Total Ascend Cost', ascendCost],
      ],
      startY: currentY,
      styles: { fontSize: 12 },
      headStyles: {
        fillColor: [78, 121, 167],
        halign: 'center',
      },
      margin: { left: 20, right: 20 },
    });

    currentY = doc.lastAutoTable.finalY + 10;

    addText('Detailed Breakdown:');
    addText('- Active Members Cost Breakdown:');
    activeMembersCostByYear.forEach((cost, year) => {
      addText(`  Year ${year + 1}: $${cost.toLocaleString()}`);
    });
    addText('- Licensing Fee:');
    addText(`  $42,000/year over 5 years.`);
    addText('- Rewards Cost Breakdown:');
    rewardsCostByYear.forEach((cost, year) => {
      addText(`  Year ${year + 1}: $${cost.toLocaleString()}`);
    });

    // 6. Revenue Breakdown
    addHeading('6. Revenue Breakdown');
    addText(
      'Unlock Revenue with Karla Ascend',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText(
      'Imagine a world where your financial institution isn\'t just attracting new members, but also converting them into long-term clients, generating predictable and significant revenue. That\'s the power of Karla Ascend.'
    );

    addHeading('Partnerships with Marketing Agencies, Landlords, and Tenant Insurance Providers', 14);
    addText(
      'Karla Ascend has built partnerships with marketing agencies, landlords, and tenant insurance providers who we can potentially convert into new members with your financial institution. We focus on marketing and conversions, while you focus on providing the true member experience that customers demand in 2025 and beyond. Together, we nurture these leads, convert them, and ensure that your financial institution thrives.'
    );

    addHeading('Revenue Projections for Mortgage Conversions', 14);
    addText(
      'Below is a table showing how much revenue your institution could generate based on different lead conversion numbers over 25 years. The potential for growth is clear when scaling from a handful of leads to hundreds.'
    );

    // Revenue Projections Table
    doc.autoTable({
      head: [['Leads Converted', 'Revenue Generated ($)']],
      body: [
        ['1', `$${(1 * totalInterestPaid).toLocaleString()}`],
        ['50', `$${(50 * totalInterestPaid).toLocaleString()}`],
        ['500', `$${(500 * totalInterestPaid).toLocaleString()}`],
      ],
      startY: currentY,
      styles: { fontSize: 12 },
      headStyles: {
        fillColor: [78, 121, 167],
        halign: 'center',
      },
      margin: { left: 20, right: 20 },
    });

    currentY = doc.lastAutoTable.finalY + 10;

    // 7. Case Study: Tom's Story
    addHeading("7. Case Study: Tom's Journey to Homeownership");
    addText(
      'Tom\'s Path from Renter to Homeowner',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText(
      'Tom, a renter for 12 years, aspired to purchase a home but faced challenges with a low credit score of 620 and insufficient savings for a down payment. Through Karla Ascend:'
    );
    addText(
      '- Credit Improvement: Utilized rent reporting to enhance his credit score.\n' +
        '- Savings Optimization: Identified and capitalized on savings opportunities.\n' +
        '- HomeFunds Program: Secured the necessary down payment.'
    );
    addText(
      'Within two years, Tom was mortgage-ready, seamlessly transitioning from a renter to a homeowner. This transformation not only fulfilled Tom\'s dream but also generated substantial revenue for your credit union.'
    );

    // 8. ESG Impact
    addHeading('8. ESG Impact');
    addText(
      'Championing Financial Inclusivity and Sustainability',
      { fontSize: 14, fontStyle: 'italic' }
    );
    addText(
      'Karla Ascend is committed to Environmental, Social, and Governance (ESG) principles by:'
    );
    addText(
      '- Social Impact: Enhancing financial literacy among underserved demographics.\n' +
        '- Governance: Maintaining transparent and ethical financial practices.\n' +
        '- Sustainability: Promoting long-term homeownership and financial stability.'
    );

    // 9. Call to Action
    addHeading('9. Unlock Your Credit Union\'s Potential');
    addText(
      'Partner with Karla Ascend to drive meaningful change and achieve unparalleled ROI.'
    );
    addText('Contact Us: robin@co-karla.com');
    addText('Visit Our Website: www.KarlaAscend.com');

    // Save the PDF
    doc.save('Karla_Ascend_Summary_Report.pdf');
  };

  return (
    <div className="App">
      {/* Step 0: Welcome Screen */}
      {step === 0 && (
        <div className="hero-screen centered-content">
          <h1>Welcome to Karla Ascend!</h1>
          <p>
            At Karla Ascend, we empower your credit union to help your members achieve the dream of homeownership.
            Our platform is designed to de-risk mortgage applications and significantly increase conversion rates.
          </p>
          <button onClick={() => setStep(step + 1)}>Explore Ascend</button>
        </div>
      )}

      {/* Step 1: Explanation Screen */}
      {step === 1 && (
        <div className="explanation-screen centered-content">
          <h2>How Karla Ascend Works</h2>
          <p>
            With our cutting-edge platform, your credit union can improve mortgage conversions for renters,
            helping more members achieve their homeownership dreams. Let's calculate how Ascend can help you.
          </p>
          <button onClick={() => setStep(step + 1)}>Continue</button>
        </div>
      )}

      {/* Step 2: Members */}
      {step === 2 && (
        <div className="question-screen centered-content">
          <h2>How many of your members do you think currently rent and could benefit from Ascend's program?</h2>
          <div className="button-group">
            <button onClick={() => handleMembersClick(2500)}>2,500 Members</button>
            <button onClick={() => handleMembersClick(5000)}>5,000 Members</button>
            <button onClick={() => handleMembersClick(7500)}>7,500 Members</button>
            <button onClick={() => handleMembersClick(10000)}>10,000 Members</button>
          </div>
        </div>
      )}

      {/* Step 3: Conversion Rate */}
      {step === 3 && (
        <div className="question-screen centered-content">
          <h2>What do you think your current mortgage conversion rate is for renters?</h2>
          <div className="button-group">
            <button onClick={() => handleConversionClick(0.005)}>0.5%</button>
            <button onClick={() => handleConversionClick(0.01)}>1%</button>
            <button onClick={() => handleConversionClick(0.015)}>1.5%</button>
          </div>
        </div>
      )}

      {/* Step 4: Mortgage Amount */}
      {step === 4 && (
        <div className="question-screen centered-content">
          <h2>What is the average mortgage amount you offer?</h2>
          <div className="button-group">
            <button onClick={() => handleMortgageClick(400000)}>$400,000</button>
            <button onClick={() => handleMortgageClick(500000)}>$500,000</button>
            <button onClick={() => handleMortgageClick(600000)}>$600,000</button>
            <button onClick={() => handleMortgageClick(700000)}>$700,000</button>
          </div>
        </div>
      )}

      {/* Step 5: Interest Rate */}
      {step === 5 && (
        <div className="question-screen centered-content">
          <h2>What is the current interest rate offered to new homeowners?</h2>
          <div className="button-group">
            <button onClick={() => handleInterestClick(4)}>4%</button>
            <button onClick={() => handleInterestClick(5)}>5%</button>
            <button onClick={() => handleInterestClick(6)}>6%</button>
            <button onClick={() => handleInterestClick(7)}>7%</button>
          </div>
        </div>
      )}

      {/* Step 6: Ready to Calculate */}
      {step === 6 && (
        <div className="calculate-ready centered-content">
          <h2>Awesome! Let's see how Ascend converts renters into mortgage owners over 5 years!</h2>
          <button onClick={handleCalculateClick}>Calculate the ROI of Ascend</button>
        </div>
      )}

      {/* Step 7: Loading ROI Calculation */}
      {step === 7 && (
        <div className="calculate-screen centered-content">
          <h2>Calculating your ROI with Ascend...</h2>
          <div className="spinner-container">
            {isCalculating && <div className="spinner"></div>}
          </div>
        </div>
      )}

      {/* Step 9: ROI Display */}
      {step === 9 && (
        <div className="roi-screen centered-content">
          <h1>Ascend's ROI Analysis</h1>
          <p>
            Your normal conversion rate was {(conversionRate * 100).toFixed(2)}%. <br />
            Now, with Karla Ascend, your new conversion rate is {(conversionRate * 10 * 100).toFixed(2)}%.
          </p>
          <p>
            Instead of {Math.round(members * conversionRate)} members converting, Ascend will unlock additional members each year, leading to increased revenue:
          </p>
          {unlockedMembersWithKarla.map((membersConverted, year) => (
            <p key={year}>
              Year {year + 1}: {membersConverted} members converted, net revenue: ${parseFloat(netRevenueWithKarla[year]).toLocaleString()}
            </p>
          ))}
          <p>Running Ascend for your {members} active members will cost ${ascendCost}.</p>
          <h2 className="roi-percentage">Ascend's ROI: {finalRoi}%!</h2>
          <button onClick={() => setStep(step + 1)}>See Cost Breakdown</button>
        </div>
      )}

      {/* Step 10: Cost Breakdown Section */}
      {step === 10 && (
        <div className="cost-breakdown-container centered-content">
          <div className="dashboard-hero">
            <h1>Ascend's Financial Dashboard</h1>
            <p className="subtext">
              Ascend empowers members by offering cost-effective solutions, providing essential financial literacy support to help overcome financial obstacles and enhance inclusivity within your credit union.
            </p>
          </div>
          <div className="dashboard-container">
            {/* Pie Chart Section */}
            <div className="chart-container pie-chart centered-chart">
              <Pie data={costData} options={costOptions} />
            </div>

            {/* Cost Breakdown Bars */}
            <div className="cost-breakdown">
              <h2>5-Year Cost Breakdown</h2>
              <div className="cost-bar active-members">
                <span>Active Members Cost ($2/member/month):</span>
                <span className="cost-amount" style={{ color: '#4e79a7' }}>
                  ${totalActiveMembersCost.toLocaleString()}
                </span>
              </div>
              <div className="cost-bar licensing-fee">
                <span>Licensing Fee ($42,000/year):</span>
                <span className="cost-amount" style={{ color: '#f28e2b' }}>
                  ${licensingFee.toLocaleString()}
                </span>
              </div>
              <div className="cost-bar rewards">
                <span>Rewards Cost:</span>
                <span className="cost-amount" style={{ color: '#76b7b2' }}>
                  ${totalRewardsCost.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
          {/* Revenue Breakdown Button */}
          <button className="revenue-button" onClick={() => setStep(step + 1)}>
            Show me the Revenue Breakdown
          </button>
        </div>
      )}

      {/* Step 11: Revenue Breakdown Section with PDF Download */}
      {step === 11 && (
        <div className="revenue-breakdown-container centered-content">
          <div className="dashboard-hero">
            <h1>Ascend's Revenue Breakdown</h1>
            <p className="subtext">
              Credit unions struggle to keep up with modern technology, while underserved demographics demand new credit-building tools. Ascend bridges this gap by providing data-driven insights, empowering credit unions to serve renters better and convert them into mortgage-ready clients.
            </p>
          </div>
          <div className="revenue-details">
            <h2>Mortgage Interest Revenue</h2>
            <p>
              With an average mortgage amount of ${mortgageAmount.toLocaleString()} and an interest rate of {interestRate}%, Ascend will ensure that members receive competitive rates while driving sustainable revenue.
            </p>
            <div className="chart-container bar-chart centered-chart" style={{ height: '600px', width: '800px' }}>
              <Bar data={revenueData} options={revenueOptions} />
              {tooltipContent && (
                <div
                  className="custom-tooltip"
                  style={{
                    top: `${tooltipPosition.y}px`,
                    left: `${tooltipPosition.x}px`,
                    transform: 'translate(-50%, -100%)',
                    position: 'absolute',
                    pointerEvents: 'none',
                  }}
                >
                  <h3>{tooltipContent.title}</h3>
                  {tooltipContent.content.split('\n').map((line, index) => (
                    <p key={index}>{line.trim()}</p>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Additional Revenue Components */}
          <div className="additional-revenue-content">
            <h2>Unlock Revenue with Karla Ascend</h2>
            <p>
              Imagine a world where your financial institution isn't just attracting new members, but also
              converting them into long-term clients, generating predictable and significant revenue. That's the
              power of Karla Ascend.
            </p>

            <h2>Partnerships with Marketing Agencies, Landlords, and Tenant Insurance Providers</h2>
            <p>
              Karla Ascend has built partnerships with marketing agencies, landlords, and tenant insurance
              providers who we can potentially convert into new members with your financial institution. We focus
              on marketing and conversions, while you focus on providing the true member experience that
              customers demand in 2025 and beyond. Together, we nurture these leads, convert them, and
              ensure that your financial institution thrives.
            </p>

            <h2>Revenue Projections for Mortgage Conversions</h2>
            <p>
              Below is a table showing how much revenue your institution could generate based on different lead
              conversion numbers over 25 years. The potential for growth is clear when scaling from a handful of
              leads to hundreds.
            </p>

            <table className="revenue-table">
              <thead>
                <tr>
                  <th>Leads Converted</th>
                  <th>Revenue Generated ($)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>${(1 * totalInterestPaid).toLocaleString()}</td>
                </tr>
                <tr>
                  <td>50</td>
                  <td>${(50 * totalInterestPaid).toLocaleString()}</td>
                </tr>
                <tr>
                  <td>500</td>
                  <td>${(500 * totalInterestPaid).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Tom's Storyline */}
          <div className="toms-story">
            <h2>Case Study: Tom's Journey</h2>
            <p>
              Tom, a renter for 12 years, aspired to purchase a home but faced challenges with a low credit score of 620 and insufficient savings for a down payment. Through Karla Ascend:
            </p>
            <ul>
              <li>
                <strong>Credit Improvement:</strong> Utilized rent reporting to enhance his credit score.
              </li>
              <li>
                <strong>Savings Optimization:</strong> Identified and capitalized on savings opportunities.
              </li>
              <li>
                <strong>HomeFunds Program:</strong> Secured the necessary down payment.
              </li>
            </ul>
            <p>
              Within two years, Tom was mortgage-ready, seamlessly transitioning from a renter to a homeowner. This transformation not only fulfilled Tom's dream but also generated substantial revenue for the credit union.
            </p>
          </div>

          {/* ESG Score Section */}
          <div className="esg-score-section">
            <h2>ESG Impact</h2>
            <p>
              Karla Ascend helps credit unions modernize their tools by enabling them to offer their clients financially inclusive tools to grow their credit worthiness, personal financial literacy, and overall acceleration towards homeownership.
            </p>
            <div className="chart-container line-chart centered-chart" style={{ height: '400px', width: '600px' }}>
              <Line
                data={esgData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: true,
                      position: 'top',
                    },
                  },
                }}
              />
            </div>
          </div>

          {/* Button to create PDF summary report */}
          <button className="pdf-button" onClick={handleDownloadPDF}>
            Download Summary Report
          </button>
          <p>
            Please download your personal Summary Report to present your results to your team or review
            with your Sales Contact at Karla Ascend.
          </p>
        </div>
      )}
    </div>
  );
}

export default App;
